/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
            instagram
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  return (
    <aside className="bio">
      <StaticImage
        className="bio-avatar"
        layout="fixed"
        formats={["auto", "webp", "avif"]}
        src="../images/profile-pic.jpg"
        width={200}
        height={200}
        quality={95}
        alt="Profile picture"
      />
      <div className="bio-textwrapper">
        <p className="bio-text">This is the bio component.</p>
        {author?.name && (
        <p className="bio-text">
          Written by <strong>{author.name}</strong> -- {author?.summary || null}
          {` `}
        </p>
      )}

        <p className="bio-text">
          <a href={`https://instagram.com/${social?.instagram || ``}`}> You should follow her on Instagram</a>
        </p>

      </div>

    </aside>
  )
}

export default Bio
